import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface IFrameWithAspectRatioProps {
  ratio: number;
  iframeProps: Omit<BoxProps<'iframe'>, 'width' | 'height'>;
}

interface IFrameWithWidthBasedAspectRatioProps extends IFrameWithAspectRatioProps {
  width: BoxProps['width'];
}

const FullSizeIFrame = (props: IFrameWithAspectRatioProps['iframeProps']) => (
  <Box component='iframe' position='absolute' top={0} left={0} width='100%' height='100%' {...props} />
);

export const IFrameWithWidthBasedAspectRatio = (props: IFrameWithWidthBasedAspectRatioProps) => (
  <Box position='relative' width={props.width} height={0} pb={props.ratio * 100 + '%'}>
    <FullSizeIFrame {...props.iframeProps} />
  </Box>
);

interface IFrameWithHeightBasedAspectRatioProps extends IFrameWithAspectRatioProps {
  height: BoxProps['height'];
}

export const IFrameWithHeightBasedAspectRatio = (props: IFrameWithHeightBasedAspectRatioProps) => (
  <Box position='relative' height={props.height} width={0} pr={props.ratio * 100 + '%'}>
    <FullSizeIFrame {...props.iframeProps} />
  </Box>
);
