import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const SaleAdrvisorImage = () => {
  return (
    <StaticImage
      quality={100}
      layout='fixed'
      width={200}
      height={200}
      style={{
        alignSelf: 'center',
        borderRadius: '50%'
      }}
      src='../../../../images/handlowiec.jpg'
      alt='handlowiec'
    />
  );
};
