import React from 'react';
import type * as CSS from 'csstype';
import { Box, Stack, Typography } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { StaticImage } from 'gatsby-plugin-image';
import Hero from './Hero';
import HeroTitle from './HeroTitle';
import { Link } from 'gatsby-material-ui-components';

// TODO: Dynamiczna szerekość strzałki żeby pasowała między sm a lg.
const FlowArrow = (props: { display: ResponsiveStyleValue<CSS.Property.Display> }) => (
  <Box
    display={props.display}
    // Wyrównanie do środka na podstawie rozmiaru ikony i strzałki.
    paddingTop='calc(170px / 2 - 20px / 2)'
  >
    <svg width='180' height='20'>
      <g transform='translate(3.590375,3.032625)'>
        <path
          fill='#ffffff'
          d='M 171,7.967 H 1 c -0.552,0 -1,-0.448 -1,-1 0,-0.552 0.448,-1 1,-1 h 170 c 0.552,0 1,0.448 1,1 0,0.552 -0.448,1 -1,1 z'
        />
        <path
          fill='#ffffff'
          d='m 165.852,13.935 c -0.256,0 -0.512,-0.098 -0.707,-0.293 -0.391,-0.39 -0.391,-1.023 0,-1.414 l 5.26,-5.261 -5.26,-5.26 c -0.391,-0.391 -0.391,-1.023 0,-1.414 0.391,-0.391 1.023,-0.391 1.414,0 l 5.967,5.967 c 0.391,0.391 0.391,1.023 0,1.414 l -5.967,5.968 c -0.196,0.195 -0.452,0.293 -0.707,0.293 z'
        />
      </g>
    </svg>
  </Box>
);

interface FunctionIconProps {
  number: number;
  link?: string;
  image: React.ReactChild;
  ariaLabel: string;
}

const FlowIcon = (props: React.PropsWithChildren<FunctionIconProps>) => (
  <Stack spacing={2} alignItems='center'>
    {props.link ? (
      <Link to={props.link} aria-label={props.ariaLabel}>
        {props.image}
      </Link>
    ) : (
      props.image
    )}
    <Typography
      variant='h6'
      component='caption'
      width='200px'
      align='center'
      textTransform='uppercase'
      color='white'
      sx={{ span: { color: 'divider' } }}
    >
      <span>{props.number}.</span> {props.children}
    </Typography>
  </Stack>
);

export default (props: React.PropsWithChildren<{ links: string[] }>) => {
  return (
    <Hero
      maxWidth='lg'
      image={
        <StaticImage
          style={{ height: '100%' }}
          layout='fullWidth'
          quality={95}
          src='../../images/hero_product.jpg'
          alt=''
        />
      }
    >
      <HeroTitle>
        Wspieramy gabinety i przychodnie
        <br />
        na każdym etapie obsługi pacjenta:
      </HeroTitle>
      <Box display='flex' justifyContent='center'>
        <Stack
          mt={{ xs: 3, md: 6 }}
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 3, md: 1 }}
          divider={<FlowArrow display={{ xs: 'none', md: 'block' }} />}
        >
          <FlowIcon
            number={1}
            link={props.links?.length > 0 ? props.links[0] : undefined}
            ariaLabel='Przed wizytą - w rejestracji'
            image={
              <StaticImage
                src='../../images/function_icon1.png'
                alt=''
                layout='fixed'
                quality={100}
                placeholder='none'
              />
            }
          >
            Przed wizytą
            <br /> - w rejestracji
          </FlowIcon>
          <FlowIcon
            number={2}
            link={props.links?.length > 1 ? props.links[1] : undefined}
            ariaLabel='Podczas wizyty - w gabinecie'
            image={
              <StaticImage
                src='../../images/function_icon2.png'
                alt=''
                layout='fixed'
                quality={100}
                placeholder='none'
              />
            }
          >
            Podczas wizyty
            <br /> - w gabinecie
          </FlowIcon>
          <FlowIcon
            number={3}
            link={props.links?.length > 2 ? props.links[2] : undefined}
            ariaLabel='Po wizycie - rozliczenia i raporty'
            image={
              <StaticImage
                src='../../images/function_icon3.png'
                alt=''
                layout='fixed'
                quality={100}
                placeholder='none'
              />
            }
          >
            Po wizycie
            <br /> - rozliczenia i raporty
          </FlowIcon>
        </Stack>
      </Box>
      {props.children}
    </Hero>
  );
};
