import React from 'react';
import { graphql } from 'gatsby';
import { Box, BoxProps, Paper } from '@mui/material';

export const query = graphql`
  fragment DemoFormData on MarkdownRemark {
    id
    frontmatter {
      order
      title
      description
      demo_form
      demo_form_width
      demo_form_height
    }
  }
`;

export interface DemoFormProps {
  src: string;
  width: BoxProps['width'];
  height: BoxProps['height'];
}

export const DemoForm = (props: DemoFormProps) => (
  <Box
    component='iframe'
    src={props.src}
    title='Formularz do uzyskania dema Mediporty'
    width={props.width}
    height={props.height}
    // Domyślnie jest inline-block co powoduje odstęp po przez line-height.
    display='block'
    // Na inne własności są pola Box a to trzeba zrobić przez sx.
    sx={{ borderStyle: 'none' }}
  />
);

export const DemoFormBorder = (props: React.PropsWithChildren<unknown>) => (
  <Box display='flex' justifyContent='center'>
    <Paper variant='outlined'>{props.children}</Paper>
  </Box>
);

export const DemoFormWithBorder = (props: DemoFormProps) => (
  <DemoFormBorder>
    <DemoForm {...props} />
  </DemoFormBorder>
);
