import { Stack, Divider } from '@mui/material';
import React from 'react';
import { BenefitsList } from './zalety/BenefitsList';
import ContactSection from './kontakt';

export const DesktopView = () => {
  return (
    <Stack direction={'row'} marginBottom={4}>
      <ContactSection />
      <Divider flexItem orientation='vertical' sx={{ marginRight: 2 }} />
      <BenefitsList />
    </Stack>
  );
};
