import { SvgIconProps, Stack } from '@mui/material';
import React from 'react';
import { ContactRow } from './ContactRow';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const iconProps: SvgIconProps = {
  sx: { color: 'divider' },
  fontSize: 'medium'
};

export const ContactDetails = () => {
  return (
    <Stack gap={1}>
      <ContactRow icon={<PhoneIcon {...iconProps} />} text='+48 61 41 51 810' />
      <ContactRow icon={<EmailIcon {...iconProps} />} text='kontakt@mediporta.pl' />
    </Stack>
  );
};
