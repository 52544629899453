import { Stack, SvgIconProps } from '@mui/material';
import React from 'react';
import PublicIcon from '@mui/icons-material/Public';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloudIcon from '@mui/icons-material/Cloud';
import { BenefitItem } from './BenefitItem';

const IconProps: SvgIconProps = {
  sx: { color: 'divider' },
  fontSize: 'medium'
};

type Benefit = {
  text: React.ReactNode;
  icon: React.ReactNode;
};

const benefits: Benefit[] = [
  {
    icon: <PublicIcon {...IconProps} />,
    text: (
      <>
        Działamy na terenie <b>całej Polski</b>
      </>
    )
  },
  {
    icon: <PeopleAltIcon {...IconProps} />,
    text: (
      <>
        Obsługujemy ponad <b>1000 Klientów </b>
      </>
    )
  },
  {
    icon: <AccessTimeIcon {...IconProps} />,
    text: (
      <>
        Realizujemy szybkie wdrożenie (nawet do <b>3 dni roboczych</b>)
      </>
    )
  },
  {
    icon: <StarIcon {...IconProps} />,
    text: (
      <>
        Posiadamy ponad <b>11 lat doświadczenia </b>w branży oprogramowania medycznego
      </>
    )
  },
  {
    icon: <CloudIcon {...IconProps} />,
    text: (
      <>
        Jesteśmy jednym z pierwszy dostawców <b>oprogramowania medycznego w chmurze</b>
      </>
    )
  }
];

export const BenefitsList = () => {
  return (
    <Stack flex={1} gap={3}>
      {benefits.map((benefit, index) => (
        <BenefitItem key={index} {...benefit} />
      ))}
    </Stack>
  );
};
