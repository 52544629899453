import { Stack, Typography } from '@mui/material';
import React from 'react';

interface BenefitItemProps {
  icon: React.ReactNode;
  text: React.ReactNode | string;
}

export const BenefitItem: React.FC<BenefitItemProps> = ({ icon, text }) => {
  return (
    <Stack direction={'row'} spacing={2}>
      {icon}
      <Typography textAlign={'justify'}>{text}</Typography>
    </Stack>
  );
};
