import React from 'react';
import type * as CSS from 'csstype';
import { Breakpoint } from '@mui/material';
import PageSectionWithImage from '../Sections/PageSectionWithImage';

interface HeroProps {
  image: React.ReactNode;
  minHeight?: CSS.Property.MinHeight;
  maxWidth?: Breakpoint;
}

export default (props: React.PropsWithChildren<HeroProps>) => <PageSectionWithImage {...props} bgimage={props.image} />;
