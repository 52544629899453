import React from 'react';
import { Box } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';

export default (props: React.PropsWithChildren<{ to: string }>) => (
  <Box mt={{ xs: 3, md: 6 }} display='flex' justifyContent='center'>
    <Button to={props.to} variant='contained' sx={{ minWidth: '60%' }}>
      {props.children}
    </Button>
  </Box>
);
