import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { DesktopView } from './DesktopView';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { MobileView } from './MobileView';

export default () => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageSection maxWidth='lg'>
      <PageSectionTitle>Porozmawiaj z naszym doradcą</PageSectionTitle>
      <Typography variant='h5' component={'h3'} marginBottom={4}>
        Odpowiemy na pytania i pomożemy zoptymalizować pracę Twojej przychodni.
      </Typography>
      {isSmallerThanMd ? <MobileView /> : <DesktopView />}
    </PageSection>
  );
};
