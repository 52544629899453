import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

interface FaqItem {
  id: string;
  question: string;
  answerHTML: string;
}

interface FaqSectionContentProps {
  items: FaqItem[];
}

export default ({ items }: FaqSectionContentProps) => {
  return (
    <Stack spacing={3} sx={{ gap: 0 }}>
      {items.map((item) => (
        <Accordion variant='outlined' disableGutters key={item.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              gap: '0.5rem'
            }}
          >
            {item.question}
          </AccordionSummary>
          <AccordionDetails sx={{ paddingY: 0 }}>
            <Box dangerouslySetInnerHTML={{ __html: item.answerHTML }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};
