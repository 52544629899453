import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ContactDetails } from './ContactDetails';
import { SaleAdrvisorImage } from './SaleAdvisorImage';

interface ContactSectionProps {
  isMobileView?: boolean;
}

const Contact: React.FC<ContactSectionProps> = ({ isMobileView }) => {
  return (
    <Stack
      direction={'row'}
      flex={1}
      gap={isMobileView ? 6 : 0}
      justifyContent={isMobileView ? 'start' : 'space-evenly'}
      minWidth={450}
    >
      <Stack gap={isMobileView ? 3 : 2} justifyContent={isMobileView ? 'start' : 'center'}>
        <Typography variant='h5' component={'h3'}>
          Skontaktuj się z nami
        </Typography>
        <ContactDetails />
      </Stack>
      <SaleAdrvisorImage />
    </Stack>
  );
};

export default Contact;
