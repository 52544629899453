import { Stack, Typography } from '@mui/material';
import React from 'react';

interface ContactRowProps {
  icon: React.ReactNode;
  text: string;
}

export const ContactRow: React.FC<ContactRowProps> = ({ icon, text }) => {
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      {icon}
      <Typography variant='subtitle1' fontWeight={600} component={'p'}>
        {text}
      </Typography>
    </Stack>
  );
};
