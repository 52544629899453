import { Divider, Stack } from '@mui/material';
import React from 'react';
import { BenefitsList } from './zalety/BenefitsList';
import ContactSection from './kontakt';

export const MobileView = () => {
  return (
    <Stack gap={5}>
      <BenefitsList />
      <Divider />
      <ContactSection isMobileView />
    </Stack>
  );
};
